import React, { useState, useEffect } from 'react';
import './App.css';
import { FaTrashAlt, FaPlusCircle } from 'react-icons/fa';

const formatCurrency = (value) => {
  const num = parseFloat(value.replace(/[$,]/g, ''));
  return isNaN(num) ? '' : `$${num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

const formatPercentage = (value) => {
  const num = parseFloat(value.replace(/[%]/g, ''));
  return isNaN(num) ? '' : `${num.toFixed(2)}%`;
};

const parseCurrency = (value) => parseFloat(value.replace(/[$,]/g, '')) || 0;

const InputField = ({ value, onChange, placeholder, type = 'text', formatFn, parseFn }) => {
  const handleInput = (e) => {
    const val = e.target.value.replace(/[^0-9.]/g, '');
    const parts = val.split('.');
    if (parts.length > 2 || (parts[1] && parts[1].length > 2)) return;
    onChange(val);
  };

  const handleBlur = (e) => {
    const val = e.target.value;
    onChange(formatFn ? formatFn(val) : val);
  };

  return (
    <input
      type={type}
      className="input"
      value={value}
      onChange={handleInput}
      onBlur={handleBlur}
      placeholder={placeholder}
      required
    />
  );
};

const TextInput = ({ value, onChange, placeholder }) => (
  <input type="text" className="input" value={value} onChange={e => onChange(e.target.value)} placeholder={placeholder} required />
);

const SelectInput = ({ value, onChange, options }) => (
  <select className="input" value={value} onChange={e => onChange(e.target.value)} required>
    {options.map(option => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
);

const Row = ({ row, handleInputChange, removeRow, fields }) => (
  <tr>
    {fields.map((field, index) => {
      const { name, component: Component, placeholder, options } = field;
      return (
        <td key={index}>
          <Component
            value={row[name]}
            onChange={value => handleInputChange(name, value)}
            placeholder={placeholder}
            options={options}
          />
        </td>
      );
    })}
    <td>
      <button type="button" className="button is-danger is-small" onClick={removeRow}>
        <FaTrashAlt />
      </button>
    </td>
  </tr>
);

const Section = ({ title, rows, addRow, handleInputChange, removeRow, fields, totalLabel, totalValue, sectionClass }) => (
  <section className={`section ${sectionClass}`}>
    <h2 className="title is-4 has-text-centered">{title}</h2>
    <div className="table-container">
      <table className="table is-fullwidth">
        <thead>
          <tr>
            {fields.map((field, index) => (
              <th key={index}>{field.label}</th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <Row
              key={rowIndex}
              row={row}
              handleInputChange={(name, value) => handleInputChange(rowIndex, name, value)}
              removeRow={() => removeRow(rowIndex)}
              fields={fields}
            />
          ))}
        </tbody>
      </table>
    </div>
    <button type="button" className="button is-primary" onClick={addRow}>
      <FaPlusCircle />
    </button>
    <div className="mt-3">
      <h3>{totalLabel}: ${totalValue}</h3>
    </div>
  </section>
);

const useRows = (initialRow) => {
  const [rows, setRows] = useState([initialRow]);

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const addRow = () => setRows([...rows, initialRow]);

  const removeRow = (index) => {
    if (rows.length > 1) {
      setRows(rows.filter((_, i) => i !== index));
    }
  };

  return [rows, setRows, handleInputChange, addRow, removeRow];
};

const calculateTotal = (rows, isMonthly = true) => {
  return rows.reduce((acc, row) => {
    const amount = parseCurrency(row.amount || row.currentValue || row.balance || '0');
    return acc + (isMonthly && row.frequency === 'monthly' ? amount : isMonthly ? amount / 12 : amount);
  }, 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

const App = () => {
  const initialIncome = { name: '', amount: '', frequency: 'monthly' };
  const initialExpense = { name: '', amount: '', frequency: 'monthly' };
  const initialAsset = { name: '', accountType: '', currentValue: '', monthlyDeposit: '', interest: '' };
  const initialDebt = { name: '', balance: '', interest: '', monthlyPayment: '' };

  const [incomeRows, setIncomeRows, handleIncomeChange, addIncomeRow, removeIncomeRow] = useRows(initialIncome);
  const [expenseRows, setExpenseRows, handleExpenseChange, addExpenseRow, removeExpenseRow] = useRows(initialExpense);
  const [assetRows, setAssetRows, handleAssetChange, addAssetRow, removeAssetRow] = useRows(initialAsset);
  const [debtRows, setDebtRows, handleDebtChange, addDebtRow, removeDebtRow] = useRows(initialDebt);

  const [totalMonthlyIncome, setTotalMonthlyIncome] = useState('0.00');
  const [totalMonthlyExpense, setTotalMonthlyExpense] = useState('0.00');
  const [totalAssets, setTotalAssets] = useState('0.00');
  const [totalDebts, setTotalDebts] = useState('0.00');

  const [payFrequency, setPayFrequency] = useState('biweekly');
  const [lastPayDate, setLastPayDate] = useState('');
  const [lastGrossIncome, setLastGrossIncome] = useState('');
  const [lastNetIncome, setLastNetIncome] = useState('');
  const [last401kContribution, setLast401kContribution] = useState('');
  const [ytd401kContribution, setYtd401kContribution] = useState('');
  const [employerMatch, setEmployerMatch] = useState('');
  const [contributionPercentage, setContributionPercentage] = useState('--');
  const [expectedTotalContribution, setExpectedTotalContribution] = useState('--');
  const [payPeriodsRemaining, setPayPeriodsRemaining] = useState('--');

  const [ptoHoursAdded, setPtoHoursAdded] = useState('');
  const [currentPtoHours, setCurrentPtoHours] = useState('');
  const [sickHoursAdded, setSickHoursAdded] = useState('');
  const [currentSickHours, setCurrentSickHours] = useState('');
  const [totalPtoHours, setTotalPtoHours] = useState('--');
  const [totalSickHours, setTotalSickHours] = useState('--');
  const [totalPtoDays, setTotalPtoDays] = useState('--');
  const [totalSickDays, setTotalSickDays] = useState('--');
  const [totalDays, setTotalDays] = useState('--');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [confirmationCallback, setConfirmationCallback] = useState(null);

  const [currentSection, setCurrentSection] = useState(0);

  useEffect(() => {
    setTotalMonthlyIncome(calculateTotal(incomeRows));
  }, [incomeRows]);

  useEffect(() => {
    setTotalMonthlyExpense(calculateTotal(expenseRows));
  }, [expenseRows]);

  useEffect(() => {
    setTotalAssets(calculateTotal(assetRows, false));
  }, [assetRows]);

  useEffect(() => {
    setTotalDebts(calculateTotal(debtRows, false));
  }, [debtRows]);

  useEffect(() => {
    update401kSummary();
    updateNetIncome();
    updatePtoSickSummary();
  }, [payFrequency, lastPayDate, lastGrossIncome, lastNetIncome, last401kContribution, ytd401kContribution, ptoHoursAdded, currentPtoHours, sickHoursAdded, currentSickHours]);

  const update401kSummary = () => {
    if (!lastGrossIncome || !last401kContribution || !lastPayDate || !ytd401kContribution) {
      setContributionPercentage('--');
      setExpectedTotalContribution('--');
      setPayPeriodsRemaining('--');
      return;
    }

    const grossIncome = parseCurrency(lastGrossIncome);
    const contribution = parseCurrency(last401kContribution);
    const ytdContribution = parseCurrency(ytd401kContribution);

    if (grossIncome === 0 || contribution === 0) {
      setContributionPercentage('--');
      setExpectedTotalContribution('--');
      setPayPeriodsRemaining('--');
      return;
    }

    const lastPay = new Date(lastPayDate);
    const endOfYear = new Date(lastPay.getFullYear(), 11, 31);
    const daysRemaining = (endOfYear - lastPay) / (1000 * 60 * 60 * 24);

    const periodsRemaining = {
      biweekly: Math.floor(daysRemaining / 14),
      'semi-monthly': Math.floor(daysRemaining / 15),
      monthly: Math.floor(daysRemaining / 30)
    }[payFrequency] || 0;

    const contributionPct = (contribution / grossIncome) * 100;
    const expectedContribution = ytdContribution + (contribution * periodsRemaining);

    setContributionPercentage(contributionPct.toFixed(2));
    setExpectedTotalContribution(expectedContribution.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
    setPayPeriodsRemaining(periodsRemaining);
  };

  const updateNetIncome = () => {
    if (!lastNetIncome) {
      return;
    }

    const netIncome = parseCurrency(lastNetIncome);
    let monthlyNetIncome = netIncome;

    if (payFrequency === 'biweekly') {
      monthlyNetIncome = (netIncome * 26) / 12;
    } else if (payFrequency === 'semi-monthly') {
      monthlyNetIncome = netIncome * 2;
    }

    const updatedRows = [...incomeRows];
    updatedRows[0] = { name: 'Net Income', amount: formatCurrency(monthlyNetIncome.toFixed(2)), frequency: 'monthly' };
    setIncomeRows(updatedRows);
  };

  const updatePtoSickSummary = () => {
    if (!ptoHoursAdded || !currentPtoHours || !sickHoursAdded || !currentSickHours) {
      setTotalPtoHours('--');
      setTotalSickHours('--');
      setTotalPtoDays('--');
      setTotalSickDays('--');
      setTotalDays('--');
      return;
    }

    const ptoAdded = parseCurrency(ptoHoursAdded);
    const currentPto = parseCurrency(currentPtoHours);
    const sickAdded = parseCurrency(sickHoursAdded);
    const currentSick = parseCurrency(currentSickHours);

    if (ptoAdded === 0 || sickAdded === 0) {
      setTotalPtoHours('--');
      setTotalSickHours('--');
      setTotalPtoDays('--');
      setTotalSickDays('--');
      setTotalDays('--');
      return;
    }

    const totalPto = currentPto + (ptoAdded * payPeriodsRemaining);
    const totalSick = currentSick + (sickAdded * payPeriodsRemaining);

    setTotalPtoHours(totalPto.toFixed(2));
    setTotalSickHours(totalSick.toFixed(2));
    setTotalPtoDays((totalPto / 8).toFixed(2));
    setTotalSickDays((totalSick / 8).toFixed(2));
    setTotalDays(((totalPto + totalSick) / 8).toFixed(2));
  };

  const verifyCredentials = async (username, password) => {
    try {
      const response = await fetch('/loadData', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        const data = await response.json();
        if (data) {
          return { success: true, data };
        } else {
          return { success: false, message: 'No user found' };
        }
      } else {
        const errorText = await response.text();
        return { success: false, message: errorText.includes('Incorrect password') ? 'Incorrect password' : 'No user found' };
      }
    } catch (error) {
      return { success: false, message: `Failed to verify credentials: ${error.message}` };
    }
  };

  const handleSave = async () => {
    const { success, message, data } = await verifyCredentials(username, password);
    if (success || message === 'No user found') {
      if (message !== 'No user found') {
        setModalMessage('User exists. Do you want to overwrite the data?');
        setConfirmationCallback(() => saveData);
        setShowModal(true);
      } else {
        saveData();
      }
    } else {
      showAlert(message);
    }
  };

  const saveData = async () => {
    try {
      const data = {
        incomeRows,
        expenseRows,
        assetRows,
        debtRows,
        payFrequency,
        lastPayDate,
        lastGrossIncome,
        lastNetIncome,
        last401kContribution,
        ytd401kContribution,
        employerMatch,
        ptoHoursAdded,
        currentPtoHours,
        sickHoursAdded,
        currentSickHours
      };

      const response = await fetch('/saveData', {
        method: 'POST',
        body: JSON.stringify({ username, password, data }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        showAlert('Data saved successfully');
      } else {
        const errorText = await response.text();
        showAlert(`Failed to save data: ${errorText}`);
      }
    } catch (error) {
      showAlert(`Failed to save data: ${error.message}`);
    }
  };

  const handleDelete = async () => {
    const { success, message } = await verifyCredentials(username, password);
    if (success) {
      setModalMessage('Are you sure you want to delete your user account and all associated data?');
      setConfirmationCallback(() => deleteUser);
      setShowModal(true);
    } else {
      showAlert(message);
    }
  };

  const deleteUser = async () => {
    try {
      const response = await fetch('/deleteUser', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        showAlert('User deleted successfully');
        setUsername('');
        setPassword('');
      } else {
        const errorText = await response.text();
        showAlert(`Failed to delete user: ${errorText}`);
      }
    } catch (error) {
      showAlert(`Failed to delete user: ${error.message}`);
    }
  };

  const handleCheckUser = async () => {
    const { success, message } = await verifyCredentials(username, password);
    if (success) {
      setModalMessage('User exists. Loading data will overwrite any data that has been entered. Do you want to proceed?');
      setConfirmationCallback(() => loadData);
      setShowModal(true);
    } else {
      showAlert(message);
    }
  };

  const loadData = async () => {
    try {
      const response = await fetch('/loadData', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        const data = await response.json();
        setIncomeRows(data.incomeRows || []);
        setExpenseRows(data.expenseRows || []);
        setAssetRows(data.assetRows || []);
        setDebtRows(data.debtRows || []);
        setPayFrequency(data.payFrequency || 'biweekly');
        setLastPayDate(data.lastPayDate || '');
        setLastGrossIncome(data.lastGrossIncome || '');
        setLastNetIncome(data.lastNetIncome || '');
        setLast401kContribution(data.last401kContribution || '');
        setYtd401kContribution(data.ytd401kContribution || '');
        setEmployerMatch(data.employerMatch || '');
        setPtoHoursAdded(data.ptoHoursAdded || '');
        setCurrentPtoHours(data.currentPtoHours || '');
        setSickHoursAdded(data.sickHoursAdded || '');
        setCurrentSickHours(data.currentSickHours || '');
        showAlert('Data loaded successfully');
      } else {
        const errorText = await response.text();
        showAlert(`Failed to load data: ${errorText}`);
      }
    } catch (error) {
      showAlert(`Failed to load data: ${error.message}`);
    }
  };

  const showAlert = (message) => {
    setModalMessage(message);
    setConfirmationCallback(null);
    setShowModal(true);
  };

  const handleModalConfirm = () => {
    if (confirmationCallback) {
      confirmationCallback();
    }
    setShowModal(false);
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const sections = [
    {
      title: "Pay Check",
      content: (
        <section className="section has-background-light">
          <h2 className="title is-4 has-text-centered">Pay Check</h2>
          <div className="field">
            <label className="label">Pay Frequency:</label>
            <SelectInput
              value={payFrequency}
              onChange={setPayFrequency}
              options={[
                { value: 'biweekly', label: 'Biweekly' },
                { value: 'semi-monthly', label: 'Semi-monthly' },
                { value: 'monthly', label: 'Monthly' }
              ]}
            />
          </div>
          <div className="field">
            <label className="label">Last Pay Date:</label>
            <input type="date" className="input" value={lastPayDate} onChange={e => setLastPayDate(e.target.value)} required />
          </div>
          <div className="field">
            <label className="label">Last Gross Income:</label>
            <InputField value={lastGrossIncome} onChange={setLastGrossIncome} placeholder="$" formatFn={formatCurrency} />
          </div>
          <div className="field">
            <label className="label">Last Net Income:</label>
            <InputField value={lastNetIncome} onChange={setLastNetIncome} placeholder="$" formatFn={formatCurrency} />
          </div>
          <div className="field">
            <label className="label">Last 401(k) Contribution:</label>
            <InputField value={last401kContribution} onChange={setLast401kContribution} placeholder="$" formatFn={formatCurrency} />
          </div>
          <div className="field">
            <label className="label">YTD 401(k) Contribution:</label>
            <InputField value={ytd401kContribution} onChange={setYtd401kContribution} placeholder="$" formatFn={formatCurrency} />
          </div>
          <div className="field">
            <label className="label">Employer Match (%):</label>
            <InputField value={employerMatch} onChange={setEmployerMatch} placeholder="%" formatFn={formatPercentage} />
          </div>
          <div id="401k-result">
            <h2 className="title is-4 has-text-centered">401(k) Summary</h2>
            <p>Your 401(k) contribution percentage is: {contributionPercentage}%</p>
            <p>Expected total 401(k) contribution by the end of the year: ${expectedTotalContribution}</p>
            <p>Remaining pay periods for the year: {payPeriodsRemaining}</p>
          </div>
          <h2 className="title is-4 has-text-centered mt-5">PTO and Sick Hours</h2>
          <div className="field">
            <label className="label">PTO hours added for this pay check:</label>
            <input type="number" className="input" value={ptoHoursAdded} onChange={e => setPtoHoursAdded(e.target.value)} required />
          </div>
          <div className="field">
            <label className="label">Current available PTO hours:</label>
            <input type="number" className="input" value={currentPtoHours} onChange={e => setCurrentPtoHours(e.target.value)} required />
          </div>
          <div className="field">
            <label className="label">Sick hours added for this paycheck:</label>
            <input type="number" className="input" value={sickHoursAdded} onChange={e => setSickHoursAdded(e.target.value)} required />
          </div>
          <div className="field">
            <label className="label">Current available sick hours:</label>
            <input type="number" className="input" value={currentSickHours} onChange={e => setCurrentSickHours(e.target.value)} required />
          </div>
          <div id="pto-sick-summary" className="mt-5">
            <h2 className="title is-4 has-text-centered">PTO and Sick Hours Summary</h2>
            <p>Expected total PTO hours by the end of the year: {totalPtoHours} hours ({totalPtoDays} days)</p>
            <p>Expected total Sick hours by the end of the year: {totalSickHours} hours ({totalSickDays} days)</p>
            <p>Total combined PTO and Sick days: {totalDays} days</p>
          </div>
        </section>
      )
    },
    {
      title: "Incomes",
      content: (
        <Section
          title="Incomes"
          rows={incomeRows}
          addRow={addIncomeRow}
          handleInputChange={handleIncomeChange}
          removeRow={removeIncomeRow}
          fields={[
            { name: 'name', label: 'Income Source', component: TextInput, placeholder: 'ex. Pay Check' },
            { name: 'amount', label: 'Amount', component: (props) => <InputField {...props} formatFn={formatCurrency} />, placeholder: '$' },
            { name: 'frequency', label: 'Frequency', component: SelectInput, options: [{ value: 'monthly', label: 'Monthly' }, { value: 'annually', label: 'Annually' }] }
          ]}
          totalLabel="Total Monthly Income"
          totalValue={totalMonthlyIncome}
          sectionClass="has-background-light"
        />
      )
    },
    {
      title: "Expenses",
      content: (
        <Section
          title="Expenses"
          rows={expenseRows}
          addRow={addExpenseRow}
          handleInputChange={handleExpenseChange}
          removeRow={removeExpenseRow}
          fields={[
            { name: 'name', label: 'Expense Item', component: TextInput, placeholder: 'ex. Rent' },
            { name: 'amount', label: 'Amount', component: (props) => <InputField {...props} formatFn={formatCurrency} />, placeholder: '$' },
            { name: 'frequency', label: 'Frequency', component: SelectInput, options: [{ value: 'monthly', label: 'Monthly' }, { value: 'annually', label: 'Annually' }] }
          ]}
          totalLabel="Total Monthly Expense"
          totalValue={totalMonthlyExpense}
          sectionClass="has-background-light"
        />
      )
    },
    {
      title: "Assets",
      content: (
        <Section
          title="Assets"
          rows={assetRows}
          addRow={addAssetRow}
          handleInputChange={handleAssetChange}
          removeRow={removeAssetRow}
          fields={[
            { name: 'name', label: 'Name', component: TextInput, placeholder: 'ex. Bank' },
            { name: 'accountType', label: 'Account Type', component: TextInput, placeholder: 'ex. Checking' },
            { name: 'currentValue', label: 'Current Value', component: (props) => <InputField {...props} formatFn={formatCurrency} />, placeholder: '$' },
            { name: 'monthlyDeposit', label: 'Monthly Deposit', component: (props) => <InputField {...props} formatFn={formatCurrency} />, placeholder: '$' },
            { name: 'interest', label: 'Interest', component: (props) => <InputField {...props} formatFn={formatPercentage} />, placeholder: '%' }
          ]}
          totalLabel="Total Assets"
          totalValue={totalAssets}
          sectionClass="has-background-light"
        />
      )
    },
    {
      title: "Debts",
      content: (
        <Section
          title="Debts"
          rows={debtRows}
          addRow={addDebtRow}
          handleInputChange={handleDebtChange}
          removeRow={removeDebtRow}
          fields={[
            { name: 'name', label: 'Name', component: TextInput, placeholder: 'ex. Mortgage' },
            { name: 'balance', label: 'Balance', component: (props) => <InputField {...props} formatFn={formatCurrency} />, placeholder: '$' },
            { name: 'interest', label: 'Interest', component: (props) => <InputField {...props} formatFn={formatPercentage} />, placeholder: '%' },
            { name: 'monthlyPayment', label: 'Monthly Payment', component: (props) => <InputField {...props} formatFn={formatCurrency} />, placeholder: '$' }
          ]}
          totalLabel="Total Debts"
          totalValue={totalDebts}
          sectionClass="has-background-light"
        />
      )
    }
  ];

  return (
    <div className="container">
      <h1 className="title has-text-centered">Financial Assistant</h1>
      <div className="tabs is-centered">
        <ul>
          {sections.map((section, index) => (
            <li key={index} className={currentSection === index ? 'is-active' : ''}>
              <a onClick={() => setCurrentSection(index)}>{section.title}</a>
            </li>
          ))}
        </ul>
      </div>
      <div className="columns">
        <div className="column is-one-third">
          <div className="sticky-container">
            <div className="box">
              <h2 className="title is-4">User Data</h2>
              <div className="field">
                <label className="label">Username</label>
                <div className="control">
                  <input type="text" className="input" value={username} onChange={e => setUsername(e.target.value)} required />
                </div>
              </div>
              <div className="field">
                <label className="label">Password</label>
                <div className="control">
                  <input type="password" className="input" value={password} onChange={e => setPassword(e.target.value)} required />
                </div>
              </div>
              <div className="buttons">
                <button className="button is-primary" onClick={handleSave}>Save Data</button>
                <button className="button is-primary" onClick={handleCheckUser}>Load Data</button>
                <button className="button is-danger" onClick={handleDelete}>Delete User</button>
              </div>
            </div>
            <div className="box sticky-summary">
              <h2 className="title is-4">Summary</h2>
              <p>Total Monthly Income:</p>
              <p className="is-size-5 has-text-weight-bold">${totalMonthlyIncome}</p>
              <p>Total Monthly Expense:</p>
              <p className="is-size-5 has-text-weight-bold">${totalMonthlyExpense}</p>
              <p>Monthly Cash Flow:</p>
              <p className="is-size-5 has-text-weight-bold">${(parseCurrency(totalMonthlyIncome) - parseCurrency(totalMonthlyExpense)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</p>
              <p>Total Assets:</p>
              <p className="is-size-5 has-text-weight-bold">${totalAssets}</p>
              <p>Total Debts:</p>
              <p className="is-size-5 has-text-weight-bold">${totalDebts}</p>
            </div>
          </div>
        </div>
        <div className="column">
          <form id="financial-form">
            {sections[currentSection].content}
            <div className="buttons is-centered mt-5">
              {currentSection > 0 && <button type="button" className="button" onClick={() => setCurrentSection(currentSection - 1)}>Previous</button>}
              {currentSection < sections.length - 1 && <button type="button" className="button is-primary" onClick={() => setCurrentSection(currentSection + 1)}>Next</button>}
            </div>
          </form>
        </div>
      </div>
      {showModal && (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="box">
              <p>{modalMessage}</p>
              {confirmationCallback ? (
                <div>
                  <button className="button is-info" onClick={handleModalConfirm}>Yes</button>
                  <button className="button is-warning" onClick={handleModalCancel}>No</button>
                </div>
              ) : (
                <button className="button is-info" onClick={() => setShowModal(false)}>OK</button>
              )}
            </div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={() => setShowModal(false)}></button>
        </div>
      )}
    </div>
  );
};

export default App;
